/**
 * @file leadInfo.js
 * @description This script captures and stores in local storage lead information from URL parameters and document referrer.
 */

enum LeadSource {
  /** User was directly referred to the website. */
  Direct = "Website - Direct",

  /* Referrer presented */
  Organic = "Website - Organic",

  /** User was referred from Ads */
  GoogleAds = "Google Ads",
  LinkedInAds = "LinkedIn Ads",
  FacebookAds = "Facebook Ads",
}

const sourceMapping: { [key: string]: LeadSource } = {
  google: LeadSource.GoogleAds,
  linkedin: LeadSource.LinkedInAds,
  facebook: LeadSource.FacebookAds,
};

const isInternalReferrer = (referrer: string) => {
  try {
    const hostname = window.location.hostname;
    const referrerUrl = new URL(referrer);
    return referrerUrl.hostname.endsWith(hostname);
  } catch (error) {
    return false;
  }
};

const formatSourceChannelId = (url: string): string => {
  // Remove protocol (http, https, etc.)
  let urlWithoutProtocol = url.replace(/(^\w+:|^)\/\//, "");

  // Remove 'www.' if present
  urlWithoutProtocol = urlWithoutProtocol.replace(/^www\./, "");

  // Replace all non-alphanumeric characters with underscores
  const formattedId = urlWithoutProtocol.replace(/[^0-9a-zA-Z_-]/g, "_");

  return formattedId;
};

export const getLeadInfo = () => {
  try {
    if (localStorage.getItem("leadInfo")) {
      // Avoid overwriting lead info on page change.
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source");
    const utmCampaign = urlParams.get("utm_campaign");

    let sourceChannel = LeadSource.Direct;
    let sourceChannelId = "";

    const referrer = document.referrer;

    if (utmSource) {
      sourceChannelId = utmCampaign || "";
      sourceChannel = sourceMapping[utmSource.toLowerCase()] || LeadSource.Organic;
    } else if (referrer && !isInternalReferrer(referrer)) {
      sourceChannel = LeadSource.Organic;
      sourceChannelId = referrer;
    }

    const leadInfo = {
      sourceChannel,
      sourceChannelId: formatSourceChannelId(sourceChannelId),
      originalUrl: window.location.href,
    };

    localStorage.setItem("leadInfo", JSON.stringify(leadInfo));
  } catch (error) {
    console.error("Error getting lead info", error);
  }
};

document.addEventListener("DOMContentLoaded", () => {
  getLeadInfo();
});
