/**
 * @file Handles scroll events to apply parallax effect on specified elements within containers.
 */

function handleScroll(): void {
  if (window.matchMedia("(max-width: 767px)").matches) return;

  const parallaxDisabled = getComputedStyle(document.documentElement).getPropertyValue(
    "--parallax-disabled",
  );
  if (parallaxDisabled === "true") return;

  // Get all parallax container elements
  const parallaxContainers: NodeListOf<HTMLElement> = document.querySelectorAll(
    "[data-parallax-container]",
  );

  // Loop through each parallax container
  parallaxContainers.forEach((container: HTMLElement) => {
    const containerScrollTop: number = container.getBoundingClientRect().top;

    // Loop through each element inside the container
    const elements: NodeListOf<HTMLElement> = container.querySelectorAll("[data-parallax]");
    elements.forEach((element: HTMLElement) => {
      const speed: string | null = element.getAttribute("data-speed");
      const offset: string | null = element.getAttribute("data-offset");
      const oposite: string | null = element.getAttribute("data-oposite");

      const isOposite: boolean = oposite === "true";

      if (speed && offset) {
        transformElement(
          element,
          containerScrollTop,
          parseFloat(speed),
          parseFloat(offset),
          isOposite,
        );
      }
    });
  });
}

/**
 * Transforms the specified element based on scroll position, speed, and offset.
 * @param element The HTML element to apply the parallax effect.
 * @param scrollTop The scroll position of the container.
 * @param speed The speed factor for the parallax effect.
 * @param offset The initial offset for the parallax effect.
 * @param oposite Whether the parallax effect should be oposite.
 */
function transformElement(
  element: HTMLElement,
  scrollTop: number,
  speed: number,
  offset: number,
  oposite: boolean = false,
): void {
  const transformValue: number = scrollTop * speed + offset;
  const transform = oposite ? -transformValue : transformValue;
  element.style.transform = `translateY(${transform}px) translateZ(0)`;
}

window.addEventListener("scroll", handleScroll);

// Initial call to handleScroll to apply correct elements position
document.addEventListener("DOMContentLoaded", handleScroll);
