/**
 * @file This file is responsible for animating the header:
 * the active link indicator that follows the mouse on hover.
 */

export function initActiveLinkIndicator() {
  const activeLinkIndicator = document.getElementById("active-link-indicator");
  const nav = document.getElementById("header-nav");
  const links = nav?.querySelectorAll("a");

  if (activeLinkIndicator && nav && links) {
    links.forEach((link) => {
      link.addEventListener("mouseenter", () => {
        const activeNavLink = nav.querySelector(".active");
        if (!activeNavLink) {
          activeLinkIndicator.style.transition = "opacity 0.3s";
        } else {
          activeLinkIndicator.style.transition = "all 0.3s";
        }

        const linkRect = link.getBoundingClientRect();
        const containerRect = nav.getBoundingClientRect();
        const relativeLeft = linkRect.left - containerRect.left;

        activeLinkIndicator.style.transform = `translateX(${relativeLeft}px)`;
        activeLinkIndicator.style.width = `${linkRect.width}px`;
        activeLinkIndicator.style.opacity = "1";
      });

      link.addEventListener("mouseleave", () => {
        const activeNavLink = nav.querySelector(".active");
        const activeLinkRect = activeNavLink?.getBoundingClientRect();
        const containerRect = nav.getBoundingClientRect();

        if (activeLinkRect) {
          const relativeLeft = activeLinkRect?.left - containerRect.left;
          activeLinkIndicator.style.transform = `translateX(${relativeLeft}px)`;
          activeLinkIndicator.style.width = `${activeLinkRect?.width}px`;
        }

        activeLinkIndicator.style.opacity = "0";
      });
    });
  }
}
